import axios from 'axios';
import {
    IBalance,
    IBonus,
    IBonusActivities,
    IUser,
} from '../types/user-context-types';
import {
    ILoginResponse,
    IRegisterError,
    IRegisterResponse,
} from '../types/user-register-types';
import { RESPONSE_MESSAGE } from '../utils';
import { API_URL } from './utils';

export const UserAPI = {
    register: async (
        fullname: string,
        phone: string,
        password: string,
        email: string,
        notification: string
    ) => {
        try {
            const response = await axios.post<IRegisterResponse>(
                API_URL + 'users/',
                {
                    fullname,
                    phone,
                    password,
                    email,
                    notification,
                }
            );

            RESPONSE_MESSAGE.success('Регистрация прошла успешно!');
            return response.data;
        } catch (error) {
            const registerError = error as IRegisterError;

            if (registerError.response.data.phone) {
                RESPONSE_MESSAGE.error(registerError.response.data.phone);
            } else if (registerError.response.data.fullname) {
                RESPONSE_MESSAGE.error(registerError.response.data.fullname);
            } else if (registerError.response.data.password) {
                RESPONSE_MESSAGE.error(registerError.response.data.password);
            } else {
                RESPONSE_MESSAGE.error('Ошибка регистрации');
            }
        }
    },
    login: async (phone: string, password: string) => {
        try {
            const response = await axios.post<ILoginResponse>(
                API_URL + 'auth/jwt/create/',
                {
                    phone,
                    password,
                }
            );

            if (response.data.access) {
                localStorage.setItem('token', response.data.access);
            }

            return response.data;
        } catch (error) {
            RESPONSE_MESSAGE.error('Неверный логин или пароль!');
        }
    },

    checkCurrentUser: async () => {
        try {
            const response = await axios.get<IUser>(API_URL + 'users/me/', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            return null;
        }
    },
    getCurrentUser: async () => {
        try {
            const response = await axios.get<IUser>(API_URL + 'users/me/', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');
                    window.location.href = '/logout';
                }
                RESPONSE_MESSAGE.error(
                    'Ошибка при получении пользователя: ' + error.message
                );
            } else {
                RESPONSE_MESSAGE.error('Неизвестная ошибка: ' + error);
            }
            return null;
        }
    },
    getBalance: async () => {
        try {
            const response = await axios.get<IBalance | 0>(
                API_URL + 'users/get_balance/',
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (error) {
            RESPONSE_MESSAGE.error('Ошибка при получении баланса: ' + error);
            return 0;
        }
    },
    getBonuses: async () => {
        try {
            const response = await axios.get<IBonus | 0>(
                API_URL + 'users/get_bonuses/',
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (error) {
            RESPONSE_MESSAGE.error('Ошибка при получении бонусов: ' + error);
            return 0;
        }
    },
    getBonusActivities: async () => {
        try {
            const response = await axios.get<IBonusActivities[]>(
                API_URL + 'users/get_bonus_activity/',
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (error) {
            RESPONSE_MESSAGE.error('Ошибка при получении бонусов: ' + error);
        }
    },
};

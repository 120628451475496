import styles from './home-page.module.less';
import { useNavigate } from 'react-router-dom';
import { OrangeButton } from '../../components/buttons/orange-button';
import { useState, useEffect } from 'react';
import { HomePageAPI } from '../../api/home-page-api';
import { CarAPI } from '../../api/cars-api';
import { DEFAULT_CATALOG_TYPE } from '../../variables';
import { PostsList } from './posts-list';
import { HomeCarsList } from './home-cars-list';
import { Preloader } from '../../components/preloader';

export const HomePage = () => {
    const [posts, setPosts] = useState([]);
    const [cars, setCars] = useState([]);
    const navigate = useNavigate();
    const fetchPosts = async () => {
        const posts = await HomePageAPI.getPosts();
        if (posts && posts.length) {
            setPosts(posts.slice(0, 3));
        }
    };
    const fetchCars = async () => {
        const cars_data = await CarAPI.getCars({
            params: {
                page: '1',
                catalog_type: DEFAULT_CATALOG_TYPE,
            },
        });
        if (cars_data && cars_data?.results.length) {
            setCars(cars_data.results.slice(0, 3));
        }
    };
    useEffect(() => {
        if (posts && !posts.length) {
            fetchPosts();
        }
        if (!cars.length) {
            fetchCars();
        }
    }, [posts, cars]);

    if (!cars.length) {
        return <Preloader />;
    }
    return (
        <>
            <section className={styles.intro}>
                <div className={styles.overlay}>
                    <div className={styles.container}>
                        <h1>Аренда с правом выкупа</h1>
                        <p>Здесь вы можете арендовать машину</p>
                        <div className={styles.btn_wrapper}>
                            <OrangeButton
                                children="ПОСМОТРЕТЬ ВСЕ МАШИНЫ"
                                handleClick={() => navigate('/cars')}
                            />
                        </div>
                    </div>
                </div>
            </section>
            {posts.length && (
                <section className={styles.posts_section}>
                    <div className={styles.container}>
                        <h2>Блог и Новости</h2>
                        <PostsList posts={posts} />
                    </div>
                </section>
            )}
            {cars.length && (
                <div className={styles.cars_section}>
                    <h2 className={styles.section_title}>Наши предложения</h2>
                    <HomeCarsList cars={cars} />
                </div>
            )}
            {false && (
                <section className={styles.service_section}>
                    <div className={styles.container}>
                        <h2 className={styles.section_title}>
                            Типы обслуживания и ремонта
                        </h2>
                        <div className={styles.services_grid}>
                            <div className={styles.service}>
                                <div>
                                    <h4 className={styles.service_title}>
                                        Техническое обслуживание
                                    </h4>
                                    <p className={styles.service_description}>
                                        Регулярное техническое обслуживание
                                        вашего автомобиля.
                                    </p>
                                </div>

                                <p className={styles.service_description}>
                                    Цена: 0000 ₽
                                </p>
                            </div>
                            <div className={styles.service}>
                                <div>
                                    <h4 className={styles.service_title}>
                                        Ремонт двигателя
                                    </h4>
                                    <p className={styles.service_description}>
                                        Диагностика и ремонт двигателя вашего
                                        автомобиля.
                                    </p>
                                </div>

                                <p className={styles.service_description}>
                                    Цена: 0000 ₽
                                </p>
                            </div>
                            <div className={styles.service}>
                                <div>
                                    <h4 className={styles.service_title}>
                                        Замена масла
                                    </h4>
                                    <p className={styles.service_description}>
                                        Быстрая и качественная замена масла.
                                    </p>
                                </div>

                                <p className={styles.service_description}>
                                    Цена: 0000 ₽
                                </p>
                            </div>
                            <div className={styles.service}>
                                <div>
                                    <h4 className={styles.service_title}>
                                        Ремонт тормозной системы
                                    </h4>
                                    <p className={styles.service_description}>
                                        Ремонт и замена тормозной системы вашего
                                        автомобиля.
                                    </p>
                                </div>

                                <p className={styles.service_description}>
                                    Цена: 0000 ₽
                                </p>
                            </div>
                            <div className={styles.service}>
                                <div>
                                    <h4 className={styles.service_title}>
                                        Замена шин
                                    </h4>
                                    <p className={styles.service_description}>
                                        Профессиональная замена и балансировка
                                        шин.
                                    </p>
                                </div>

                                <p className={styles.service_description}>
                                    Цена: 0000 ₽
                                </p>
                            </div>
                            <div className={styles.service}>
                                <div>
                                    <h4 className={styles.service_title}>
                                        Диагностика подвески
                                    </h4>
                                    <p className={styles.service_description}>
                                        Проверка и ремонт амортизационной
                                        системы вашего автомобиля.
                                    </p>
                                </div>

                                <p className={styles.service_description}>
                                    Цена: 0000 ₽
                                </p>
                            </div>
                        </div>
                        <div className={styles.btn_wrapper}>
                            <a href="tel:+79539708877"></a>
                            <OrangeButton
                                children="Позвонить сейчас"
                                handleClick={() => 1}
                            />
                        </div>
                    </div>
                </section>
            )}
        </>
    );
};
